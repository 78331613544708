@import '../../../styles/uitilities.scss';

.order_summary {
    padding: size(15) size(15) size(20);
    background-color: $grey-2;
    border: 1px solid $grey;
    border-radius: 2px;
    margin-bottom: size(29);

    @include max(767.98) {
        margin-top: size(38);
    }

    .summary_title {
        margin-bottom: size(16);
        text-transform: capitalize;
    }

    .summary {
        &_data {
            font-size: size(14);
            display: flex;
            justify-content: space-between;
            color: $title-alt-color;
            margin-bottom: size(6);

            &:last-child {
                margin-bottom: 0;
            }

            sup {
                margin-right: 1px;
            }

            span {
                font-size: 14px;
            }
        }

        &_total {
            margin-top: size(25);
            display: flex;
            justify-content: space-between;
            color: $black;
            font-weight: $font-bold;

        }
    }
}

[dir=rtl] {
    .summary {
        &_data {
            div:last-child {
                display: flex;
                direction: ltr;
                sup{
                    top: 0;
                }
            }
        }

        &_total {
            div:last-child {
                display: flex;
                direction: ltr;
                sup{
                    top: 0;
                }
            }
        }
    }
}