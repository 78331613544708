@import "../../styles/uitilities.scss";
body {
  position: relative;
}
.strip_wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: $black;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  column-gap: 5px;
  @include max(767) {
    justify-content: start;
    padding: 0 15px;
  }
  img {
    flex: 0 0 22px;
    max-width: 22px;
    transition: 0.3s opacity ease-in-out;
  }
  &:hover {
    img,
    a {
      opacity: 0.8;
      color: #fff;
      text-decoration: none;
    }
  }
}
.strip_text a {
  color: #fff;
  font-size: 14px;
  line-height: 1.5;
  text-decoration: none;
  transition: 0.3s opacity ease-in-out;
  position: inherit;
  &::before {
    content: "";
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: all;
  }
  &:active,
  &:focus {
    color: #fff;
  }
}
