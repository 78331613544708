@import "../../styles/uitilities.scss";

.gift_card_value{
    margin-top: 23px;
    &_container{
        @include min(768){
            background-color: #F5F5F5;
            padding: size(30);
            margin-bottom: 25px;
        }
        @include min(1200){
          padding: size(50);
        }
        @include min(1600){
          padding: size(50) size(100);
        }
        @include min(1900){
          padding: size(50) size(155);
        }
    }
}