@import '../../../styles/uitilities.scss';

.order_summary {
    padding: size(16) size(12) size(26);
    background-color: $white;
    border-radius: size(4);
    border-inline-start: size(4) solid $vat-alert;

    -webkit-box-shadow: 0px 1px 8px 0px rgba(0,0,0,0.08);
    -moz-box-shadow: 0px 1px 8px 0px rgba(0,0,0,0.08);
    box-shadow: 0px 1px 8px 0px rgba(0,0,0,0.08);

    margin-top: size(47);

    @include max(767.98) {
        margin-top: size(38);
    }
    &_sec {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        column-gap: 12px;
    }
    &_icon {
        display: block;
        width: size(25);
        height: size(25);
        flex-basis: size(25);
        max-width: size(25);
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='20' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 7.5C12.6904 7.5 13.25 6.94036 13.25 6.25C13.25 5.55964 12.6904 5 12 5C11.3096 5 10.75 5.55964 10.75 6.25C10.75 6.94036 11.3096 7.5 12 7.5Z' fill='%23E00751'/%3E%3Cpath d='M11 9V15H13V9H11Z' fill='%23E00751'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 0C6.47715 0 2 4.47715 2 10C2 15.5228 6.47715 20 12 20C17.5228 20 22 15.5228 22 10C22 4.47715 17.5228 0 12 0ZM4 10C4 5.58172 7.58172 2 12 2C16.4183 2 20 5.58172 20 10C20 14.4183 16.4183 18 12 18C7.58172 18 4 14.4183 4 10Z' fill='%23E00751'/%3E%3C/svg%3E%0A");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
        color: $red;
        img {
            max-width: 100%;
        }
    }
    &_content{
        flex: 1 1;
        max-width: calc(100% - 37px);
        .summary{
            &_title {
                margin-bottom: 4px;
            }
            &_desc{
                font-size: 14px;
                font-weight: $font-regular;
                color: $title-alt-color;
                line-height: 1.57;
            }
        }
    }

    // .summary {
    //     &_data {
    //         font-size: size(14);
    //         display: flex;
    //         justify-content: space-between;
    //         color: $title-alt-color;
    //         margin-bottom: size(6);

    //         &:last-child {
    //             margin-bottom: 0;
    //         }

    //         sup {
    //             margin-right: 1px;
    //         }

    //         span {
    //             font-size: 14px;
    //         }
    //     }

    //     &_total {
    //         margin-top: size(25);
    //         display: flex;
    //         justify-content: space-between;
    //         color: $black;
    //         font-weight: $font-bold;

    //     }
    // }
}

[dir=rtl] {
    .summary {
        &_data {
            div:last-child {
                display: flex;
                direction: ltr;
                sup{
                    top: 0;
                }
            }
        }

        &_total {
            div:last-child {
                display: flex;
                direction: ltr;
                sup{
                    top: 0;
                }
            }
        }
    }
}
