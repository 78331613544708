@import "../../styles/uitilities.scss";
@import "../../styles/common.scss";
.transaction{
  &_wrapper{
    width: 100%;
    border-radius: 20px;
    padding: 15px;
    border: 1px solid #adadad;
    margin: 20px 0;
    @include min(768){
      width: 85%;
      margin: 20px auto;
    }
    @include min(1200){
      margin: 20px auto;
      width: 55%;
      padding: 25px;
    }
    @include min(1600){
      margin: 20px auto;
      width: 50%;
      padding: 25px;
    }
  }
  &_heading {
    text-align: center;
  }
  &_content {
    text-align: center;
    padding: 20px 0 0;
    &_title {
      font-size: 20px;
      font-weight: 700;
      line-height: 1;
      margin-bottom: 20px;
      color: #000;
      text-align: left;
    }
    .order{
      &_details{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: start;
      }
    }
    .fig_wrapper {
      width: 350px;
      max-width: 100%;
      margin: auto;
      margin-bottom: 30px;
      .fig_img_wrapper {
        position: relative;
        padding-bottom: calc((200 / 350) * 100%);
        width: 100%;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: contain;
          -o-object-fit: contain;
          top: 0;
          left: 0;
        }
      }
    }
    p {
      color: #000;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.3;
      margin-bottom: 15px;
      text-align: left;
      &.card_status_items {
        color: #484848;
        padding-bottom: 0;
        font-size: 14px;
        span {
          color: #000000;
        }
      }
    }
    .order_details {
      margin: 15px auto;
      .order_details_row {
        width: 100%;
        p{
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
    a {
      color: #000;
      text-decoration: none;
      transition: 0.3s all ease-in-out;
      font-weight: 600;
      @include min(1024) {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .btn_wrapper {
      margin: 30px auto 0;
      .home_button {
        color: #fff;
        line-height: 1.3;
        background-color: #0058a3;
        border: 1px solid #0058a3;
        text-transform: uppercase;
        transition: 0.3s all ease-in-out;
        text-decoration: none;
        display: inline-block;
        @include min(1024) {
          &:hover {
            background-color: #013b6e;
            border-color: #013b6e;
          }
        }
        &:active,
        &:focus,
        &:focus-visible {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}
@include max(767) {
  .transaction_content {
    .title_content {
      font-size: 18px;
    }
    p {
      font-size: 14px;
    }
    .btn_wrapper {
      .home_button {
        font-size: 14px;
      }
    }
  }
}

// Transaction failed
.contentWrapper {
  text-align: center;
  .fig_wrapper {
    width: 250px;
    max-width: 100%;
    margin: auto;
    margin-bottom: 30px;
    @include max(767) {
      margin-top: 30px;
    }
    .fig_img_wrapper {
      position: relative;
      padding-bottom: calc((200 / 300) * 100%);
      width: 100%;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
        -o-object-fit: contain;
        top: 0;
        left: 0;
      }
    }
    @include min(1200) {
      width: 300px;
    }
  }
  button {
    margin-bottom: 25px;
    text-transform: uppercase;
    margin-top: size(20);
    @include max(767) {
      padding: 10px 25px;
    }
  }
}
.message {
  &_title {
    font-weight: 700;
    display: block;
    color: $black;
    text-align: center;
    margin-bottom: size(10);

    line-height: 1.2;
    font-size: size(20);
    @include min(992) {
      line-height: 27px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
  &_status {
    font-size: size(16);
    text-align: center;
    color: #000;
    font-weight: 600;
    margin-bottom: size(10);
  }
  &_summary {
    font-size: size(14);
    text-align: center;
    color: $black;
    margin-bottom: size(15);
  }
}
.card_status{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &_block{
    text-align: left;
  }
  &_items{
    font-size: size(14);
    color: #484848;
    font-weight: 500;
    padding-bottom: 5px;
    span{
      font-weight: 700;
      padding-left: 3px;
    }
  }
}