@import "../../../styles/uitilities.scss";

.payment_detail {
  margin-top: size(27);
  @include min(1200){
    padding-inline: size(26);

  }
  &_title {
    font-size: size(24);
    font-weight: $font-bold;
  }
  .field_wrapper{
    margin: 21px 0 15px;
    :global{
      .form-check{
        margin: 0;
        label{
          padding-inline-start: size(35);
          font-size: 16px;
          @include min(1200){
            font-size: size(20);
          }
        }
      }
    }
  }
  .amount_summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: size(20);
    color: $black;
    margin-bottom: size(24);
    font-weight: $font-regular;
    span {
      font-weight: $font-bold;
      color: $title-alt-color;
      font-size: size(24);
      display: block;
    }
  }

  .payment_btn {
    padding: size(18) size(15);
    min-width: 1px;
    width: 100%;
    font-weight: $font-bold;
  }

  .bank_error {
    color: #f00;
    font-size: 12px;
    margin-top: 8px;
    text-align: center;
  }
}

.form_control {
  background: $white;
  border: 1px solid $grey;
  border-radius: 4px;
  height: calc(size(40) + 8px) !important;
  &:read-only{
    background: $white;
    border: 1px solid $grey;
  }
  &:focus,
  &:hover {
    border-color: darken($grey, 20%);
    box-shadow: unset;
  }
}

select.form_control {
  margin-bottom: size(15);
}

.form_group {
  margin-bottom: size(15);

  label {
    padding: size(8) size(15) !important;
  }

  .form_control {
    padding: size(17) size(15) size(5) !important;

    &:focus ~ label,
    &:not(:placeholder-shown) ~ label {
      transform: scale(0.7) translateY(-0.5rem) translateX(0.15rem) !important;
    }
  }
}

.error {
  color: $red;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: -25px;
}


.form_label_wrapper{
  display: flex;
  padding-bottom: 10px;
  svg{
    margin-left: 8px;
  }
}
.apple_pay{
  margin-bottom: 10px;
  width: max-content;
  position: relative;
  &::after{
    content: "";
    position: absolute;
    left: 40px;
    top: 48%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    // background-image: url("./apple-logo.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }
  label{
    // padding-left: 4rem;
  }
}
[dir="rtl"] {
  .amount_summary {
    span {
      display: inline-flex;
      flex-direction: row-reverse;
      align-items: center;
    }
  }
  .payment_detail{
    .form_label_wrapper{
      :global(.form-check){
        label{
          padding-left: 8px;
        }
      }
    }
  }
  .apple_pay{
    label{
      // padding-right: 4rem;
    }
    &::after{
      left: 35px;
      right: unset;
    }
  }
}
