@import "../../../styles/uitilities.scss";

.gift_card_summary{
    margin: 27px 0 30px;
    @include min(768){
        margin-bottom: 0;
    }
    @include min(992){
        margin-top: 0;
    }
    .order_summary{
        background-color: $white;
        padding: 28px 18px;
        box-shadow: 0 3px 10px 0 #0000001a;
        @include min(1200){
            padding: 40px 27px;
        }
        &_title{
            font-size: 15px;
            font-weight: $font-bold;
            color: $black;
            line-height: 1.5;
            @include min(1200){
                font-size: 24px;
            }
        }
        &_list{
            margin: 0;
            margin-top: 13px;
            padding: 0;
            list-style: none;
            @include min(1200){
                margin-top: 13px;
            }
            &_item{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 18px;
                @include min(1200){
                    padding-bottom: 27px;
                }
                &.total{
                    padding: 12px 0;
                    border-block: 2px solid $grey;
                    @include min(1200){
                        padding: 16px 0;
                    }
                }
                .order_label{
                    font-size: 13px;
                    font-weight: $font-regular;
                    color: $black;
                    line-height: 1.5;
                    @include min(1200){
                        font-size: 20px;
                    }
                }
                .order_price{
                    font-size: 13px;
                    font-weight: $font-regular;
                    color: $black;
                    line-height: 1.5;
                    @include min(1200){
                        font-size: 20px;
                    }
                    &.bonus{
                        font-size: 15px;
                        font-weight: $font-bold;
                        @include min(1200){
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }
}
