@import '../../../styles/uitilities.scss';

.gift_cards_delivery {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    column-gap: 30px;
    margin-top: 30px;
    @include min(1200){
        column-gap: 40px;
    }
    :global{
        .form-check{
            margin: 0;
            .form-check-label{
                padding-inline-start: size(37);
            }
        }
    }
    .set_date{
        width: 100%;
        margin-top: 20px;
        @include min(1200){
            margin-top: 25px;
        }
    }
}


.submit_error {
    font-size: 12px;
    color: #f00;
    text-align: center;
    margin-top: 10px;
}
  